var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1"},[_vm._v("Hyvityslaskut")]),_c('v-card',{staticClass:"mt-2"},[_c('v-container',[_c('search-by-dates',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 0),expression:"tab === 0"}]}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.getInvoiceSearchLabelByService('refundInvoice'),"single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"mr-5 mb-2",attrs:{"color":"info"},on:{"click":function($event){$event.preventDefault();return _vm.getDataFromApi()}}},[_vm._v("Etsi")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.resetForms()}}},[_vm._v("Tyhjennä")])],1)],1)],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.invoices,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-tabs',{staticClass:"mb-3",attrs:{"color":"primary","fixed-tabs":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2)]},proxy:true},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.switchToAccount(item.createdBy, '/refund-invoices')}}},[_c('strong',[_vm._v(_vm._s(item.createdBy.name))])])]}},{key:"item.invoiceNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("# "+_vm._s(item.invoiceNumber ? item.invoiceNumber : "-")+" ")])]}},{key:"item.billDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.billDate)))])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dueDate)))])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [(item.totalAmount)?_c('span',{staticClass:"font-weight-bold error--text mr-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.totalAmount))+" ")]):_c('span',{staticClass:"font-weight-bold error--text mr-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.totalAmount)))]),(item.paidAmounts.length > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.selected = item;
            _vm.dialogPaidAmounts = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-edit-outline")])],1):_vm._e()]}},{key:"item.tenant",fn:function(ref){
            var item = ref.item;
return [(_vm.invoiceTenant(item).isReceiver)?_c('div',[_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.switchToAccount(
                item.createdBy,
                ("/tenant/overview/" + (_vm.invoiceTenant(item).tenant.tenantId._id))
              )}}},[_vm._v(_vm._s(item.tenant.name))])]):_c('div',[_c('span',[_vm._v(_vm._s(item.tenant.name))]),_c('br'),_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.switchToAccount(
                item.createdBy,
                ("/tenant/overview/" + (_vm.invoiceTenant(item).tenant.tenantId._id))
              )}}},[_vm._v(_vm._s(_vm.invoiceTenant(item).tenant.tenantId.name))])])]}},{key:"item.contract",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.switchToAccount(
              item.createdBy,
              ("/apartment/overview/" + (item.contract.apartment.id._id))
            )}}},[_vm._v(_vm._s(item.contract.apartment.id.address)),(item.contract.apartment.id.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.contract.apartment.id.apartmentNumber)+" ")]):_vm._e()]),_vm._l((_vm.getRentedRooms(item.contract.apartment)),function(room,idx){return _c('div',{key:idx + 'a'},[_c('p',{staticClass:"draft--text",staticStyle:{"margin-bottom":"-2px !important"}},[_vm._v(_vm._s(room.name))])])})]}},{key:"item.sendType",fn:function(ref){
            var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.getSendIcon(item.sendType)))])],1)]}},{key:"item.outgoingPayments",fn:function(ref){
            var item = ref.item;
return [(item.outgoingPayments.inUse)?_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v("Käytössä")]):_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v("Ei käytössä")])]}},{key:"item.lastComment",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.formatCommentsInTableRow(item.comments)))]),(item.comments.length > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){(_vm.selected = item), (_vm.dialogComments = true)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-edit-outline ")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(
              _vm.isAuthorized('invoice', 'read') &&
              !item.active &&
              !_vm.hasServiceId(item) &&
              _vm.canDownloadPreviewInvoice(item)
            )?_c('v-icon',{staticClass:"grid-item",attrs:{"title":"Esikatsele","small":""},on:{"click":function($event){_vm.downloadItem = Object.assign({}, item);
              _vm.downloadPdf(true, null);}}},[_vm._v("mdi-magnify")]):_vm._e(),(_vm.isAuthorized('invoice', 'read') && _vm.hasServiceId(item))?_c('v-icon',{attrs:{"title":"Lataa pdf","small":""},on:{"click":function($event){_vm.downloadItem = Object.assign({}, item);
              _vm.downloadPdf(false, null);}}},[_vm._v("mdi-file-pdf")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei hyvityslaskuja")])]},proxy:true}],null,true)}),_c('comments-dialog',{attrs:{"invoice":_vm.selected},model:{value:(_vm.dialogComments),callback:function ($$v) {_vm.dialogComments=$$v},expression:"dialogComments"}}),_c('paid-amounts',{attrs:{"invoice":_vm.selected},model:{value:(_vm.dialogPaidAmounts),callback:function ($$v) {_vm.dialogPaidAmounts=$$v},expression:"dialogPaidAmounts"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }